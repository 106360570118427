import moment from "moment-timezone";

import { ActionType, ChatChannel, ChatStore } from "./model";

const initialState: ChatStore = {
  channels: [],
  upcomingShifts: {},
};

const sortChannels = (list: ChatChannel[]) => {
  return list.sort((a: ChatChannel, b: ChatChannel) => {
    const a1 = a.lastMessage ? a.lastMessage.createdAt : a.createdAt;
    const b1 = b.lastMessage ? b.lastMessage.createdAt : b.createdAt;
    if (a.unreadMessageCount && b.unreadMessageCount) {
      return b1 - a1;
    }

    if (a.unreadMessageCount) {
      return -1;
    }
    if (b.unreadMessageCount) {
      return 1;
    }

    if (a.shift && b.shift) {
      return moment(a.shift.start).diff(b.shift.start);
    }
    if (a.shift) {
      return -1;
    }
    if (b.shift) {
      return 1;
    }

    return b1 - a1;
  });
};

const setMetadata = (list: ChatChannel[]) => {
  for (const item of list) {
    const {
      facilityName,
      hcpName,
      lastBooked,
      placementCandidateAccessId,
      placementId,
      placementTitle,
    } = item.getCachedMetaData() as ChatChannel["metadata"];
    item.metadata = {
      facilityName,
      hcpName,
      lastBooked,
      placementCandidateAccessId,
      placementId,
      placementTitle,
    };
  }
};

export const chatStore = (state = initialState, action: any): ChatStore => {
  switch (action.type) {
    case ActionType.SET_CHANNELS: {
      const newChannels = action.data.channels;
      setMetadata(newChannels);

      const oldChannels = state.channels;
      return {
        ...state,
        channels: sortChannels(
          newChannels.map((channel: ChatChannel) => {
            const oldChannel = oldChannels.find((oldChannel) => oldChannel.url === channel.url);
            if (oldChannel) {
              return { ...channel, shift: oldChannel.shift };
            }

            return channel;
          })
        ),
      };
    }
    case ActionType.UPDATE_CHANNEL: {
      const updatedChannel = action.data.channel;
      const foundChannel = state.channels.find((item) => item.url === updatedChannel.url);
      if (foundChannel) {
        return {
          ...state,
          channels: sortChannels(
            state.channels.map((item) =>
              item.url === updatedChannel.url ? { ...item, ...updatedChannel } : item
            )
          ),
        };
      } else {
        return {
          ...state,
          channels: sortChannels([...state.channels, updatedChannel]),
        };
      }
    }
    case ActionType.SET_UPCOMING_SHIFTS: {
      // Comes from https://github.com/ClipboardHealth/cbh-mobile-app/blob/f08219226cd74b6fbf851c777bd0bd8577501a55/src/app/store/chat/actions.ts#L22
      // Note - Redux data is of type `any`. From the API call, this may be undefined.
      const { shiftsByFacility } = action.data;

      return {
        upcomingShifts: shiftsByFacility,
        channels: sortChannels(
          state.channels.map((channel) => {
            const shift = shiftsByFacility?.[channel.url.split("_")[0]];
            if (shift) {
              return { ...channel, shift };
            }

            return channel;
          })
        ),
      };
    }
    default:
      return state;
  }
};
