import { ActionType, NotificationSettings } from "./model";

const initialState: NotificationSettings = {
  status: false,
  msg: [],
  messageId: null,
  unreadCount: 0,
};

const notificationStore = (state: NotificationSettings = initialState, action: any) => {
  switch (action.type) {
    case ActionType.SET_TOAST:
      return {
        ...state,
        status: true,
        msg: [...state.msg, ...action.data],
        messageId: action.messageId,
      };
    case ActionType.REMOVE_TOAST:
      return {
        ...state,
        status: true,
        msg: [...action.data],
        messageId: null,
      };
    case ActionType.SET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.count,
      };

    default:
      return state;
  }
};

export { notificationStore };
