import { ActionType, OngoingShiftStore } from "./model";

const initialState: OngoingShiftStore = {
  isLoading: true,
  shiftList: [], // Will contain basic info for the ongoing list
  shiftInfoList: [], // Will contain detailed shift info needed for signature
  actionList: {
    RECORD_SHIFT_TIME_FAILURE: [],
    UPLOAD_TIMECARD: [],
  },
};

const ongoingShiftStore = (state = initialState, action: any): OngoingShiftStore => {
  switch (action.type) {
    case ActionType.SET_STATE_FROM_LOCAL:
      return {
        ...state,
        ...action.data,
      };

    case ActionType.SET_ACTION_LIST:
      return {
        ...state,
        actionList: action.data,
      };

    case ActionType.SET_SHIFT_LIST:
      return {
        ...state,
        shiftList: action.data,
      };

    case ActionType.SET_SHIFT_INFO_LIST:
      return {
        ...state,
        shiftInfoList: action.data,
      };

    case ActionType.UPDATE_SHIFT: {
      const updatedInfo = action.data.updatedShift;
      let updatedList = state.shiftList;
      if (!state.shiftList.find((x) => x?._id === updatedInfo?._id)) {
        updatedList = [updatedInfo, ...updatedList];
      }
      return {
        ...state,
        shiftList: updatedList.map((shift) => {
          if (shift?._id === action.data.shiftId) {
            return { ...shift, ...updatedInfo };
          }
          return shift;
        }),
      };
    }

    case ActionType.FILTER_RECORD_SHIFT_TIME_FAILURE: {
      const { actionList } = state;
      return {
        ...state,
        actionList: {
          ...actionList,
          RECORD_SHIFT_TIME_FAILURE: actionList.RECORD_SHIFT_TIME_FAILURE.filter(
            (query) => query.timestamp !== action.data.timestamp
          ),
        },
      };
    }

    case ActionType.FILTER_UPLOAD_TIMECARD: {
      const { actionList } = state;
      return {
        ...state,
        actionList: {
          ...actionList,
          UPLOAD_TIMECARD: actionList.UPLOAD_TIMECARD.filter(
            (query) => query.timestamp !== action.data.timestamp
          ),
        },
      };
    }

    case ActionType.PUSH_RECORD_SHIFT_TIME_FAILURE: {
      const { actionList } = state;
      actionList.RECORD_SHIFT_TIME_FAILURE.push(action.data);
      return {
        ...state,
        actionList: { ...actionList },
      };
    }

    case ActionType.PUSH_UPLOAD_TIMECARD: {
      const { actionList } = state;
      actionList.UPLOAD_TIMECARD.push(action.data);
      return {
        ...state,
        actionList: { ...actionList },
      };
    }

    default: {
      return state;
    }
  }
};

export { ongoingShiftStore };
