import { retryOnceParams } from "@src/app/api/utils";
import {
  SHIFT_ACTION_CHECK_TYPES,
  SIGNED_TYPE,
  TIMESHEET_TYPE,
} from "@src/app/hcpShifts/constants";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { dataURLToBlob } from "@src/app/utils/mediaUpload";
import { environmentConfig } from "@src/appV2/environment";
import { logEvent } from "@src/appV2/lib/analytics";
import { ComplianceProofVideo } from "@src/appV2/Shifts/Shift/TakeClockActionPicture/VideoDialog";
import { USER_EVENTS } from "@src/constants/userEvents";
import { logApiFailureEvent } from "@src/lib/analytics";
import {
  ExtraWorkedTimeParameters,
  Shift,
  ShiftStages,
  Shift_NEW,
  TimeRange,
  TimecardV2,
  UnverifiedShift,
} from "@src/lib/interface";
import moment from "moment-timezone";
import request from "superagent";

import { NoTimeSheetAvailableReq } from "../../store/ongoingShifts/model";
import { getAuthHeader } from "../../superagent";
import { uploadTimesheetToS3Storage } from "../../utils/fileUpload";

export const DEFAULT_TIMESHEET_LOCATION = {
  LONGITUDE: "12",
  LATITUDE: "34",
};

const uploadSubmittedTimeSheet = async ({
  submitClockInOut,
  submitLunchInOut,
  shiftId,
  facilityEmployeeName,
  fileType,
  fileBlob,
  extraWorkedTime,
}: {
  submitClockInOut: TimeRange;
  submitLunchInOut: TimeRange;
  shiftId: string;
  facilityEmployeeName: string;
  fileType: string;
  fileBlob: Blob | undefined;
  extraWorkedTime?: ExtraWorkedTimeParameters[];
}): Promise<TimecardV2> => {
  if (!fileBlob) {
    throw new Error("fileBlob not found");
  }
  const fileUploadResult = await uploadTimesheetToS3Storage(fileBlob, fileType, shiftId);
  logEvent(USER_EVENTS.UPLOAD_CLOUDINARY_SUCCESS, {
    shiftId,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });
  const {
    body: {
      response: { timecard },
    },
  } = await request
    .put(`${environmentConfig.REACT_APP_BASE_API_URL}/v2/shifts/timecard/${shiftId}`)
    .set(await getAuthHeader())
    .send({
      timecard: fileUploadResult,
      location: [DEFAULT_TIMESHEET_LOCATION.LONGITUDE, DEFAULT_TIMESHEET_LOCATION.LATITUDE],
      submitClockInOut,
      submitLunchInOut,
      digitallySignedBy: facilityEmployeeName,
      extraWorkedTime,
    });
  logEvent(USER_EVENTS.UPLOAD_API_SUCCESS, {
    shiftId,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });

  return timecard;
};

export const uploadCaliforniaTimesheet = async ({
  shiftId,
  submittedFiles,
  submitClockInOut,
  submitLunchInOut,
  digitallySignedByHCP,
  digitallySignedBy,
  stationWingUnitFloor,
  nursingServiceAssignment,
  extraWorkedTime,
}: {
  shiftId: string;
  submittedFiles: SelectedFile[];
  submitClockInOut: TimeRange;
  submitLunchInOut: TimeRange;
  digitallySignedByHCP: string;
  digitallySignedBy: string;
  stationWingUnitFloor: string;
  nursingServiceAssignment: string;
  extraWorkedTime?: ExtraWorkedTimeParameters[];
}): Promise<TimecardV2 | undefined> => {
  const [
    { file: hcpSignatureFile, type: hcpSignatureFileType },
    { file: hcfSignatureFile, type: hcfSignatureFileType },
  ] = submittedFiles;
  const [hcpSignatureFileBlob, hcfSignatureFileBlob] = await Promise.all([
    dataURLToBlob(hcpSignatureFile!),
    dataURLToBlob(hcfSignatureFile!),
  ]);
  const [hcpSignatureResult, hcfSignatureResult] = await Promise.all([
    uploadTimesheetToS3Storage(hcpSignatureFileBlob, hcpSignatureFileType, shiftId),
    uploadTimesheetToS3Storage(hcfSignatureFileBlob, hcfSignatureFileType, shiftId),
  ]);
  logEvent(USER_EVENTS.UPLOAD_CLOUDINARY_SUCCESS, {
    shiftId,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });
  const response = await request
    .put(`${environmentConfig.REACT_APP_BASE_API_URL}/v2/shifts/timecard/${shiftId}`)
    .set(await getAuthHeader())
    .send({
      timecard: [
        {
          ...hcpSignatureResult[0],
          timesheet: TIMESHEET_TYPE.PHOTO,
          signedType: SIGNED_TYPE.HCP,
          digitallySignedBy: digitallySignedByHCP,
        },
        {
          ...hcfSignatureResult[0],
          timesheet: TIMESHEET_TYPE.PHOTO,
          signedType: SIGNED_TYPE.HCF,
          digitallySignedBy: digitallySignedBy,
        },
      ],
      /*
        location is not validated on the backend but it will fail if not provided
        so we provide a default location, until we remove the param from the backend
      */
      location: [DEFAULT_TIMESHEET_LOCATION.LONGITUDE, DEFAULT_TIMESHEET_LOCATION.LATITUDE],
      submitClockInOut,
      submitLunchInOut,
      digitallySignedByHCP,
      digitallySignedBy,
      stationWingUnitFloor,
      nursingServiceAssignment,
      extraWorkedTime,
    });
  logEvent(USER_EVENTS.UPLOAD_API_SUCCESS, {
    shiftId,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });
  return response?.body?.response?.timecard;
};

const updateShiftTimecardUrl = async (fileUploadResult: any, shiftId: string) => {
  logEvent(USER_EVENTS.UPLOAD_CLOUDINARY_SUCCESS, {
    shiftId: shiftId,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });
  const {
    body: {
      response: { timecard },
    },
  } = await request
    .put(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/timecard/${shiftId}`)
    .set(await getAuthHeader())
    .send({
      timecard: fileUploadResult,
      location: [DEFAULT_TIMESHEET_LOCATION.LONGITUDE, DEFAULT_TIMESHEET_LOCATION.LATITUDE],
    });

  logEvent(USER_EVENTS.UPLOAD_API_SUCCESS, {
    shiftId,
    uploadTimeEnd: moment().format("YYYY MMM DD HH:mm:SS"),
  });
  return timecard;
};

const uploadTimecardV2 = async (
  type: string,
  fileBlob: Blob | undefined,
  shiftId: string
): Promise<TimecardV2> => {
  if (!fileBlob) {
    throw new Error("fileBlob not found");
  }
  const fileUploadResult = await uploadTimesheetToS3Storage(fileBlob, type, shiftId);
  const timecard = await updateShiftTimecardUrl(fileUploadResult, shiftId);
  return timecard;
};

const fetchAgentUnverifiedShifts = async (): Promise<UnverifiedShift[]> => {
  return request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/getUnverifiedShifts`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body.response);
};

const setTimeSheetAvailability = async (query: NoTimeSheetAvailableReq): Promise<Shift_NEW> => {
  return request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/setTimeSheetAvailability`)
    .set(await getAuthHeader())
    .send(query)
    .then(({ body }) => body.response);
};

export type RecordTimekeepingCallback = (params: { data?: any; error?: string }) => Promise<void>;
export interface RecordTimeKeepingParams {
  shiftId: string;
  stage: ShiftStages;
  appType: string;
  shiftActionCheck: SHIFT_ACTION_CHECK_TYPES;
  location?: number[];
  locationType?: string;
  complianceProof?: ComplianceProofVideo;
  onRequestSettled: RecordTimekeepingCallback;
}

export async function recordTimeKeeping({
  shiftId,
  stage,
  location,
  locationType,
  appType,
  shiftActionCheck,
  complianceProof,
  onRequestSettled,
}: RecordTimeKeepingParams) {
  try {
    const url = `${environmentConfig.REACT_APP_BASE_API_URL}/shifts/record_timekeeping_action/${shiftId}`;

    const requestBody = {
      shiftId,
      stage,
      location: shiftActionCheck === SHIFT_ACTION_CHECK_TYPES.NFC ? undefined : location,
      locationType: shiftActionCheck === SHIFT_ACTION_CHECK_TYPES.NFC ? undefined : locationType,
      appType,
      connectivityMode: "ONLINE",
      shiftActionCheck,
      complianceProof,
    };
    const response = await request
      .post(url)
      .retry(...retryOnceParams)
      .set(await getAuthHeader())
      .send(requestBody);
    await onRequestSettled({ data: response.body });
    return response.body;
  } catch (error) {
    logApiFailureEvent(error);
    const errorMessage = (error as any)?.response?.body?.message || "Could not complete the action";
    await onRequestSettled({ error: errorMessage });
  }
}

export interface ClockInParams {
  shiftId: string;
  location?: number[];
  locationType?: string;
  appType: string;
  shiftActionCheck: SHIFT_ACTION_CHECK_TYPES;
  onRequestSettled: RecordTimekeepingCallback;
}

export async function clockInViaBff({
  shiftId,
  location,
  locationType,
  appType,
  shiftActionCheck,
  onRequestSettled,
}: ClockInParams) {
  try {
    const url = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/timekeeping/clock-in`;
    const requestBody = {
      data: {
        type: "clock-in",
        attributes: {
          shiftId,
          location: shiftActionCheck === SHIFT_ACTION_CHECK_TYPES.NFC ? undefined : location,
          locationType:
            shiftActionCheck === SHIFT_ACTION_CHECK_TYPES.NFC ? undefined : locationType,
          appType,
          shiftActionCheck,
        },
      },
    };
    const response = await request
      .post(url)
      .retry(...retryOnceParams)
      .set(await getAuthHeader())
      .send(requestBody);
    await onRequestSettled({ data: response.body.data });
    return response.body;
  } catch (error) {
    logApiFailureEvent(error);
    const errorMessage = (error as any)?.response?.body?.message || "Could not complete the action";
    await onRequestSettled({ error: errorMessage });
  }
}
interface ValidateTimeKeepingProps {
  shiftId: string;
  editedClockIn: string;
  editedClockOut: string;
}

export async function validateTimeKeeping({
  shiftId,
  editedClockIn,
  editedClockOut,
}: ValidateTimeKeepingProps) {
  const params = new URLSearchParams({
    editedClockIn,
    editedClockOut,
  }).toString();
  try {
    await request
      .get(
        `${environmentConfig.REACT_APP_BASE_API_URL}/shifts/timekeeping/validate/${shiftId}?${params}`
      )
      .retry(...retryOnceParams)
      .set(await getAuthHeader())
      .send();

    return undefined;
  } catch (error) {
    return (error as any)?.response?.body?.message as string;
  }
}

export const getUpcomingShiftsGrouped = async (): Promise<
  | {
      [key: string]: Shift;
    }
  | undefined
> => {
  return request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/upcomingShiftsGrouped`)
    .set(await getAuthHeader())
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .then(({ body }) => body?.response);
};

export {
  uploadTimecardV2,
  fetchAgentUnverifiedShifts,
  setTimeSheetAvailability,
  uploadSubmittedTimeSheet,
};
