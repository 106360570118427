import { AccountSettings, ActionType } from "./model";

const initialState: AccountSettings = {
  addressEditDetails: {},
};

const accountSettingsStore = (state: AccountSettings = initialState, action: any) => {
  switch (action.type) {
    case ActionType.SET_ADDRESS_EDIT_DETAILS:
      return {
        ...state,
        addressEditDetails: action.data,
      };

    case ActionType.UPDATE_ADDRESS_LINE_1:
      return {
        ...state,
        addressEditDetails: {
          ...state.addressEditDetails,
          addressLine1: action.data,
        },
      };

    default:
      return state;
  }
};

export { accountSettingsStore };
